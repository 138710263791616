<template>
  <div>
    <Hero>
      <Container class="last:mb-0">
        <h1 class="mb-2 capitalize">Oh dear</h1>
      </Container>
    </Hero>

    <div class="py-6 sm:py-12 md:py-20">
      <Container>
        <p class="fs-4">
          We couldn't seem to find that page,
          <router-link :to="countryHomePath">want to go back home?</router-link>
        </p>
      </Container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";

export default {
  components: {
    Container,
    Hero,
  },
  computed: {
    ...mapGetters(["countryHomePath"]),
  },
};
</script>
